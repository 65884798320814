.healthsnap-pdf-view {
    padding: 30px;

    .pdf-header-frame {
        display: flex;
        align-items: center;
        padding-bottom: 11px;
        justify-content: space-between;
        border-bottom: 0.5px dashed #9AA0A3;

        .header-label {
            font-size: 32px;
            color: #1B93C5;
            font-weight: bold;
        }

        .subheader-label {
            font-size: 16px;
        }
    }

    .groups-list-view {
        margin: 0px;
        padding: 0px;
        list-style: none;

        .section-header {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 40px;
            text-transform: uppercase;
        }

        .section-frame {
            margin-bottom: 20px;
            border-bottom: 0.5px dashed #9AA0A3;

            .questions-list-view {
                margin: 0px;
                padding: 0px;
                list-style: none;

                .question-cell {
                    display: flex;
                    justify-content: space-between;

                    .section-label {
                        font-size: 16px;
                        padding: 5px 0px;
                        text-transform: uppercase;
                    }
                }

                .question-cell:last-child {
                    margin-bottom: 20px;
                }

                .section-frame {
                    display: flex;
                    justify-content: space-between;

                    .section-label {
                        text-transform: uppercase;
                    }
                }
            }

            .section-table-view {
                .table-headers {
                    display: flex;
                    margin-bottom: 2.5px;
                    justify-content: space-between;

                    .table-header {
                        flex: 0 0 24%;
                        text-transform: uppercase;
                    }
                }

                .section-list-view {
                    margin: 0px;
                    list-style: none;
                    padding: 5px 0px 20px 0px;

                    .section-cell {
                        display: flex;
                        justify-content: space-between;
                    
                        .section-label {
                            flex: 0 0 24%;
                            font-size: 16px;
                            font-weight: bold;
                            padding: 5px 0px;
                        }

                        .status {
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }

        .section-frame.row {
            display: flex;
            padding: 20px 0px;
            align-items: center;
            justify-content: space-between;

            .section-header {
                margin-bottom: 4px;
            }

            .section-label {
                padding: 5px 0px;
                text-transform: uppercase;
            }
        }
    }
}